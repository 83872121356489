class Burger {
  constructor(burgerSelector, menuSelector) {
    this._burgetSelector = burgerSelector;
    this._menuSelector = menuSelector
    this._burgerButtonElement = document.querySelector(`.${burgerSelector}`);
    this._menuElement = document.querySelector(`.${menuSelector}`);
    this._menuLinkElementsList = [...document.querySelectorAll(`.${menuSelector} li a`)];

  }

  _toggle() {
    this._burgerButtonElement.classList.toggle(`${this._burgetSelector}_is-active`);
    this._menuElement.classList.toggle(`${this._menuSelector}_is-active`);
    this._freezePage();
  }

  _close() {
    this._burgerButtonElement.classList.remove(`${this._burgetSelector}_is-active`);
    this._menuElement.classList.remove(`${this._menuSelector}_is-active`);
  }

  _freezePage() {
    document.querySelector('.root').classList.toggle('lock')
  }

  setEventListener() {
    this._burgerButtonElement.addEventListener('click', () => this._toggle());
    this._menuLinkElementsList.forEach(element => element.addEventListener('click', () => this._close()));
  }
}

export default Burger;