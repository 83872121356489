
export default class Popup {
  constructor(popupSelector) {
    this._popupElement = document.querySelector(popupSelector);
    this._handleEscClose = this._handleEscClose.bind(this);
    this._rootElement = document.querySelector('.root');
  }

  open() {
    this._popupElement.classList.add("popup_is-opened");
    this._rootElement.classList.add('lock')
    document.addEventListener("keydown", this._handleEscClose);
  }

  close() {
    this._popupElement.classList.remove('popup_is-opened');
    document.removeEventListener("keydown", this._handleEscClose);
    this._rootElement.classList.remove('lock');
  }

  _handleEscClose(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  setEventListeners() {
    const closeButton = this._popupElement.querySelector('.popup__close-button');
    closeButton.addEventListener('click', () => { this.close()} );
  }
}