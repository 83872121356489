export const accordionTitleElements = document.querySelectorAll('.accordion__button');

export default function toggleAccordion()  {
  const accordionTitleElementToggle = this.getAttribute('aria-expanded');
  for (let i = 0; i < accordionTitleElements.length; i++) {
    accordionTitleElements[i].setAttribute('aria-expanded', 'false');
  }

  if (accordionTitleElementToggle === 'false') {
    this.setAttribute('aria-expanded', 'true')
  }
}
