export default class FormValidator {
  constructor(formSelector, [inputSelector, buttonSelector, checkboxCaptchaButtonSelector]) {
    this._formElement = document.querySelector(formSelector);
    this._inputElements = [...document.querySelectorAll(inputSelector)];
    this._buttonElement = this._formElement.querySelector(buttonSelector);
    this._checkboxInputElement = document.querySelector('.message-form__checkbox-input');
  }

  _showInputError(inputElement) {
    const errorElement = this._formElement.querySelector(`.${inputElement.name}-error`);
    inputElement.classList.add('message-form__input_is-error');
    errorElement.classList.add('message-form__error_is-visible');
    errorElement.textContent = inputElement.validationMessage;
  }

  _hideInputError(inputElement) {
    const errorElement = this._formElement.querySelector(`.${inputElement.name}-error`);
    inputElement.classList.remove('message-form__input_is-error');
    errorElement.classList.remove('message-form__error_is-visible');
    errorElement.textContent = '';
  }

  _checkInputValidity(inputElement) {
    inputElement.validity.valid
      ? this._hideInputError(inputElement)
      : this._showInputError(inputElement);
  }

  _hasInvalidInput() {
    return this._inputElements.some((inputElement) => {
      return !inputElement.validity.valid;
    })
  }


  _toggleButtonState() {
    if (this._checkboxInputElement.checked) {
      if (this._hasInvalidInput()) {
        this._buttonElement.classList.add('message-form__submit-button_is-inactive');
        this._buttonElement.setAttribute('disabled', true);
      } else {
        this._buttonElement.classList.remove('message-form__submit-button_is-inactive');
        this._buttonElement.removeAttribute('disabled', true);
      }} else {
      this._buttonElement.classList.add('message-form__submit-button_is-inactive');
      this._buttonElement.setAttribute('disabled', true);
    }
  }

  _setEventListenersInputs() {
    this._inputElements.forEach((inputElement) => {
      inputElement.addEventListener('input', () => {
        this._checkInputValidity(inputElement);
        this._toggleButtonState();
      });
      this._toggleButtonState();
    })
  }

  _setEventListenerCheckbox() {
    this._checkboxInputElement.addEventListener('click', () => {
      this._toggleButtonState();
    })
  }

  resetValidation() {
    this._toggleButtonState();

    this._inputElements.forEach((inputElement) => {
      this._hideInputError(inputElement);
    })
  }

  enableValidation() {
    this._setEventListenerCheckbox();
    this._setEventListenersInputs();
  }
}
