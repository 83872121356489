import Swiper, { Pagination } from 'swiper';
import $ from 'jquery';
import { fancyBoxInit } from './vendors/fancyapps';
import { changeLanguage } from './modules/switch-language';
import { accordionTitleElements } from './modules/accordion';
import toggleAccordion from './modules/accordion';
import Popup from './modules/Popup';
import Burger from './modules/Burger';
import CurrentYear from './modules/CurrentYear';
import FormValidator from './modules/FormValidator';
import FormMessage from './modules/FormMessage';
import { ScrollUpButton } from './modules/ScrollUpButton';
const messageButtonElement = document.querySelector('.interface-button_type_message');

fancyBoxInit();
changeLanguage();

/* Burger menu */
const burgerExemplar = new Burger('burger', 'header-menu');
burgerExemplar.setEventListener();

/* Swiper
**************************************************************/
let swiperPrograms = Swiper;
let init = false;

/* Which media query
**************************************************************/
function swiperMode() {
  let mobile = window.matchMedia('(min-width: 0px) and (max-width: 800px)');
  let desktop = window.matchMedia('(min-width: 801px)');

  if(mobile.matches) {
    if (!init) {
      init = true;
      swiperPrograms = new Swiper('.swiper', {
        modules: [Pagination],
        speed: 800,
        loop: true,
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      });

    } else if (desktop.matches) {
      swiperPrograms.destroy();
      init = false;
    }
  }

}



/* On Load
**************************************************************/
window.addEventListener('load', function() {
  swiperMode();
});

/* On Resize
**************************************************************/
window.addEventListener('resize', function() {
  swiperMode();
});

let mobile = window.matchMedia('(min-width: 0px) and (max-width: 800px)');
let desktop = window.matchMedia('(min-width: 801px)');

let distance;
mobile.matches ? distance = 0 : distance = 100;

const $root = $('html, body');


$('a[href^="#"]').click(function () {
  $('body').removeClass('lock');
  $root.animate({
    scrollTop: $( $.attr(this, 'href') ).offset().top - distance
  }, 1000);

  return false;
});

export const popupMessageForm = new Popup('.popup-form');
popupMessageForm.setEventListeners();
const formMessage = new FormMessage('.message-form');
formMessage.handleSubmit();
const formMessageValidation = new FormValidator('.message-form', [
  '.message-form__input',
  '.message-form__submit-button',
  '.CheckboxCaptcha-Button',
]);

formMessageValidation.enableValidation();


// Listeners
accordionTitleElements.forEach(item => item.addEventListener('click', toggleAccordion));
messageButtonElement.addEventListener('click', () => {
  formMessageValidation.resetValidation();
  formMessage.resetTooltip();
  popupMessageForm.open();
});

// Карта


ymaps.ready(initMap);

function initMap () {
  // Создание экземпляра карты и его привязка к контейнеру с
  // заданным id ("map").
  let myMap = new ymaps.Map('map', {
    preset: 'islands#redIcon',
    center: [44.05483592718703,43.06688943689845],
    zoom: 15,
  }, {
    searchControlProvider: 'yandex#search'
  });

  let myPlacemark = new ymaps.Placemark([44.05479762263354,43.06691479667877], {
    balloonContentHeader: 'Пятигорский государственный университет',
    balloonContent: 'Ставропольский край, Пятигорск, проспект Калинина, 9'
  }, {
    preset: 'islands#icon',
    iconColor: '#d97f77',
    iconImageSize: [80, 80]
  });

  myMap.geoObjects.add(myPlacemark);

  myMap.behaviors.disable('scrollZoom')
}

const footerCopyrightYear = new CurrentYear('.footer__copyright__year');
footerCopyrightYear.getYear();



const spinnerContainerElement = document.querySelector('.spinner-container');


window.onload = function(){
  disabledPreloader();
};

const disabledPreloader = () => {
  spinnerContainerElement.classList.remove('spinner-container_page-loading');
  spinnerContainerElement.classList.remove('spinner-container_is-active');
}

const scrollUp = new ScrollUpButton('.interface-button_type_scroll-up');
scrollUp.setEventListeners();