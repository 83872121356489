export default class FormMessage {
  constructor(formSelector) {
    this._messageFormElement = document.querySelector(formSelector);
    this._preloader = document.querySelector('.spinner-container');
    this._buttonSubbmit = document.querySelector('.message-form__submit-button');
    this._tooltipElement = document.querySelector('.message-form__tooltip');
  }

  handleSubmit() {
    this._messageFormElement.addEventListener('submit', async (event) => {
      event.preventDefault();
      const formData = new FormData(this._messageFormElement);
      try {
        this._preloader.classList.add('spinner-container_is-active');
        const response = await fetch('../../modules/send-email.php', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          this._tooltipElement.classList.add('message-form__tooltip_is-active');
          this._tooltipElement.textContent = 'Ваше письмо успешно отправлено.';
          this._messageFormElement.reset();
          this._handleSuccessTooltip();
        } else if (response.status === 403) {
          this._handleErrorTooltip();
          this._tooltipElement.textContent = 'Необходимо подтвердить, что вы не робот.';
        } else {
          this._handleErrorTooltip();
          this._tooltipElement.textContent = 'Произошла ошибка';
        }
      } catch (e) {
        throw new Error(e);
      }
      this._preloader.classList.remove('spinner-container_is-active');
    });
  }

  _handleErrorTooltip() {
    this._tooltipElement.classList.add('message-form__tooltip_is-active');
    this._tooltipElement.classList.add('message-form__tooltip_is-error');
  }

  _handleSuccessTooltip() {
    this._tooltipElement.classList.remove('message-form__tooltip_is-error');
    this._tooltipElement.classList.add('message-form__tooltip_is-active');
  }

  resetTooltip() {
    this._tooltipElement.classList.remove('message-form__tooltip_is-error');
    this._tooltipElement.classList.remove('message-form__tooltip_is-active');
    this._tooltipElement.textContent = '';
  }

}