import languageContent from './../utils/language.json';
const languageSwitcherElement = document.querySelector('.language-switcher');
const allLang = ['en', 'ru'];

const changeURLLanguage = () => {
  let language = languageSwitcherElement.value;
  location.href = `${window.location.pathname}#${language}`;
  location.reload();
}

languageSwitcherElement.addEventListener('click', changeURLLanguage);

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const changeLanguage = () => {
  let hash = window.location.hash;
  hash = hash.substr(1);

  if (!allLang.includes(hash)) {
    location.href = `${window.location.pathname}#ru`;
    location.reload();
  }

  if (hash === 'ru') {
    languageSwitcherElement.value = 'en';
    languageSwitcherElement.classList.add('header-options__language-switcher_en');
    languageSwitcherElement.textContent = 'English'
  } else {
    languageSwitcherElement.value = 'ru';
    languageSwitcherElement.classList.add('header-options__language-switcher_ru');
    languageSwitcherElement.textContent = 'Русский язык'
  }
  for(let key in languageContent) {
    let element = document.querySelector(`.lng-${key}`);
    if (element) {
      element.innerHTML = capitalizeFirstLetter(languageContent[key][hash]);
    }
  }
}

changeLanguage();
