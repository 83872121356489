export class ScrollUpButton {
  constructor(buttonSelector) {
    this._buttonUpElement = document.querySelector(buttonSelector);
  }

  _setVisibility() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      this._buttonUpElement.classList.remove('interface-button_is-hidden');
    } else {
      this._buttonUpElement.classList.add('interface-button_is-hidden');
    }
  }

  _scrollUp() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  setEventListeners() {
    window.addEventListener('scroll', () => this._setVisibility());
    this._buttonUpElement.addEventListener('click', () => this._scrollUp());
  }
}
